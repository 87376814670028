import React from "react";
import { Link } from "gatsby";
import NavBarTwo from "../components/NavbarTwo";
import Footer from "../components/Footer";
import { SEO } from "../components/seo";
const datenschutz = () => {
    return (
        <>
        <NavBarTwo/>
        <section className="text-gray-600 body-font" id="main">
  <div className="container mx-auto  max-w-7xl flex px-5 py-48 md:flex-row flex-col items-center">
    <div className="lg:flex-grow  lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
     <div className="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
      <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Datenschutzerklärung</h1><br/>


<h2>1) Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h2>

<p><b>1.1</b>&nbsp;Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.</p>

<p><b>1.2</b>&nbsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist WOP Merchandising GmbH, Max-Planck-Strasse 12, 70806 Kornwestheim, Deutschland, Tel.: 07154-8085771, Fax: 07154-8085772, E-Mail: ebay@wop-store.de. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>

<p><b>1.3</b>&nbsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge &bdquo;https://&#8220; und dem Schloss-Symbol in Ihrer Browserzeile erkennen.</p>

<h2>2) Datenerfassung beim Besuch unserer Website</h2>

<p>Bei der blo&szlig; informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server &uuml;bermittelt (sog. &bdquo;Server-Logfiles&#8220;). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:</p>

<ul>
<li>Unsere besuchte Website</li>
<li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
<li>Menge der gesendeten Daten in Byte</li>
<li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
<li>Verwendeter Browser</li>
<li>Verwendetes Betriebssystem</li>
<li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
</ul>

<p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>

<h2>3) Cookies</h2>

<p>Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endger&auml;t und erm&ouml;glichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie unterscheiden kann.</p>

<p>Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchf&uuml;hrung des Vertrages oder gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.</p>

<p>Wir arbeiten unter Umst&auml;nden mit Werbepartnern zusammen, die uns helfen, unser Internetangebot f&uuml;r Sie interessanter zu gestalten. Zu diesem Zweck werden f&uuml;r diesen Fall bei Ihrem Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit vorbenannten Werbepartnern zusammenarbeiten, werden Sie &uuml;ber den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen Informationen innerhalb der nachstehenden Abs&auml;tze individuell und gesondert informiert.</p>

<p>Bitte beachten Sie, dass Sie Ihren Browser so einstellen k&ouml;nnen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en k&ouml;nnen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemen&uuml; jedes Browsers beschrieben, welches Ihnen erl&auml;utert, wie Sie Ihre Cookie-Einstellungen &auml;ndern k&ouml;nnen. Diese finden Sie f&uuml;r die jeweiligen Browser unter den folgenden Links:</p>

<p>Microsoft Edge: <a href="https://support.microsoft.com/de-de/microsoft-edge/tempor&auml;res-zulassen-von-cookies-und-website-daten-in-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2" target="_blank">https://support.microsoft.com<wbr/>/de-de<wbr/>/microsoft-edge<wbr/>/tempor<wbr/>&auml;res-zulassen-von-cookies-und-website-daten-in-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2</a><br />
Firefox: <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">https://support.mozilla.org<wbr/>/de<wbr/>/kb<wbr/>/cookies-erlauben-und-ablehnen</a><br />
Chrome: <a href="http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647" target="_blank">http://support.google.com<wbr/>/chrome<wbr/>/bin<wbr/>/answer.py<wbr/>?hl=de<wbr/>&amp;hlrm=en<wbr/>&amp;answer=95647</a><br />
Safari: <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com<wbr/>/de-de<wbr/>/guide<wbr/>/safari<wbr/>/sfri11471<wbr/>/mac</a><br />
Opera: <a href="http://help.opera.com/Windows/10.20/de/cookies.html" target="_blank">http://help.opera.com<wbr/>/Windows<wbr/>/10.20<wbr/>/de<wbr/>/cookies.html</a></p>

<p>Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.</p>

<h2>4) Kontaktaufnahme</h2>

<p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

<h2>5) Rechte des Betroffenen</h2>

<p><b>5.1</b>&nbsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten die nachstehenden Betroffenenrechte (Auskunfts- und Interventionsrechte), wobei f&uuml;r die jeweiligen Aus&uuml;bungsvoraussetzungen auf die angef&uuml;hrte Rechtsgrundlage verwiesen wird:</p>

<ul>
<li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO;</li>
<li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO;</li>
<li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO;</li>
<li>Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO;</li>
<li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO;</li>
<li>Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO;</li>
<li>Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO;</li>
<li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO.</li>
</ul>

<p><b>5.2</b>&nbsp;WIDERSPRUCHSRECHT</p>

<p>WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT EINZULEGEN.<br />
MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.</p>

<p>WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.</p>

<p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>

<h2>6) Dauer der Speicherung personenbezogener Daten</h2>

<p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).</p>

<p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.</p>

<p>Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>

<p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>

<p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.</p>

<p>Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>      


    </div>

  </div>
</section>   
        <Footer/>
        </>

    )
}

export default datenschutz;

export const Head = ({data}) => {
  return (
  <>
 

  <SEO/>
  </>
  )
}