import * as React from "react" 
import { useState } from "react";
import { Link} from "gatsby";

const NavBarTwo = () => {

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
        console.log(open);
    }

    const closeMobile = () => {
        setOpen(false);
    }



    return (
     
        <nav className="bg-secondary relative">
          
          
          
          
          <div className="container mx-auto max-w-7xl flex container justify-between items-center p-5 bg-secondary ">
        
     
                <div>
                    <Link to="/"  className="cursor-pointer flex title-font font-medium items-center text-gray-900 ">

                    <span className="ml-3 text-xl text-primary">Digitscom</span>
                    </Link>
                </div>
                <div className="md:hidden">
                <button className="hamburger md:hidden px-5" aria-label="Mobile Nav" onClick={handleToggle}>
                 {/* icon from heroicons.com */}
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-5"
                viewBox="0 0 18 18"
                fill="rgb(217 119 6)"
                >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                />
                </svg>
                </button>
                 </div>
           
            <div className={ `${open ? 'transition-all  ease-in-out duration-850  opacity-[100] text-2xl flex flex-col md:flex-row   	md:h-auto  mx-0 w-full absolute top-20 left-0 border-t-2 border-primary md:border-none' : 'opacity-[0] -translate-x-full w-full absolute top-20 left-0 border-t-2 border-transparent' }   transition-all  ease-in-out duration-350  md:transform md:translate-x-0

 md:opacity-100 h-screen md:h-auto  bg-secondary	 md:bg-transparent text-2xl flex flex-col space-y-8 md:space-y-0 md:flex-row md:text-base md:flex justify-items-center  md:justify-end md:relative  md:top-0`}>
                <div className="container p-5 mx-auto mt-5 flex justify-end md:hidden">
                <span onClick={closeMobile} className="text-center  md:hidden  text-slate-200 px-5">X</span>

                </div>
                <div className="md:relative flex flex-col space-y-8 md:space-y-0 md:flex-row md:text-base md:flex justify-items-center  md:justify-end">

                <Link to="/marktplaetze" activeClassName="border-b-4 md:border-b-2 border-primary " className="self-center md:self-auto mb-4 md:mb-0  md:p-0  cursor-pointer md:mr-5 text-slate-200 hover:text-slate-400  text-center md:text-left  ">Marktplatzlösungen</Link>
                <Link to="/ecommerce" activeClassName="border-b-4 md:border-b-2 border-primary "  className="self-center md:self-auto mb-4 md:mb-0  md:p-0 cursor-pointer md:mr-5 text-slate-200 hover:text-slate-400    text-center md:text-left">E-Commerce</Link>
                <Link to="/d2c" activeClassName="border-b-4 md:border-b-2 border-primary "  className="self-center md:self-auto mb-4 md:mb-0  md:p-0 cursor-pointer md:mr-5 text-slate-200 hover:text-slate-400   text-center md:text-left">D2C</Link>
                <Link to="/webentwicklung" activeClassName="border-b-4 md:border-b-2 border-primary "  className="self-center md:self-auto mb-4 md:mb-0  md:p-0 cursor-pointer md:mr-0 text-slate-200 hover:text-slate-400  text-center md:text-left">Webentwicklung</Link>

                </div>
            </div>
            
     
        </div> 
        </nav>
       
    )
    }


export default NavBarTwo;

