import React from "react";
import { Link } from "gatsby";


const Footer = ()=>{

return (

    
    <footer className="text-slate-100	 bg-secondary body-font">
  <div className="container px-5 max-w-7xl py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
      <Link to="/"className="cursor-pointer flex title-font font-medium items-center md:justify-start justify-center text-primary">

        <span className="text-xl">Digitscom</span>
      </Link>
      <p className="mt-2 text-sm text-gray-500">Roomando GmbH<br/>Büroanschrift: <br/>Max-Planck-Straße 12<br/>70806 Kornwestheim</p>
    </div>
    <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
      <div className="lg:w-1/3 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-primary tracking-widest text-sm mb-3">Dienstleistungen</h2>
        <ul className="list-none mb-10">
          <li>
            <Link to="/marktplaetze" className="cursor-pointer text-slate-500 hover:text-slate-300">Marktplatz Lösungen</Link>
          </li>
          <li>
            <Link to="/ecommerce" className="cursor-pointer text-slate-500 hover:text-slate-300">E-Commerce Einstieg</Link>
          </li>
          <li>
            <Link to="/d2c" className="cursor-pointer text-slate-500 hover:text-slate-300">D2C Lösungen</Link>
          </li>
          <li>
            <Link to="/webentwicklung" className="cursor-pointer text-slate-500 hover:text-slate-300">Webseiten und Shops</Link>
          </li>
        </ul>
      </div>
      <div className="lg:w-1/3 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-primary tracking-widest text-sm mb-3">Rechtliches</h2>
        <ul className="list-none mb-10">
          <li>
            <Link to="/datenschutz" className="cursor-pointer text-slate-500 hover:text-slate-300">Datenschutzerklärung</Link>
          </li>
          <li>
            <Link to="/impressum" className="cursor-pointer text-slate-500 hover:text-slate-300">Impressum</Link>
          </li>
          
        </ul>
      </div>
      {/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-primary tracking-widest text-sm mb-3">CATEGORIES</h2>
        <nav className="list-none mb-10">
          <li>
            <a className="cursor-pointer text-slate-500 hover:text-slate-300">First Link</a>
          </li>
          <li>
            <a className="cursor-pointer text-slate-500 hover:text-slate-300">Second Link</a>
          </li>
          <li>
            <a className="cursor-pointer text-slate-500 hover:text-slate-300">Third Link</a>
          </li>
          <li>
            <a className="cursor-pointer text-slate-500 hover:text-slate-300">Fourth Link</a>
          </li>
        </nav>
      </div> */}
      {/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-primary tracking-widest text-sm mb-3">CATEGORIES</h2>
        <nav className="list-none mb-10">
          <li>
            <a className="cursor-pointer text-gray-500 hover:text-slate-300">First Link</a>
          </li>
          <li>
            <a className="cursor-pointer text-gray-500 hover:text-slate-300">Second Link</a>
          </li>
          <li>
            <a className="cursor-pointer text-gray-500 hover:text-slate-300">Third Link</a>
          </li>
          <li>
            <a className="cursor-pointer text-gray-500 hover:text-slate-300">Fourth Link</a>
          </li>
        </nav>
      </div> */}
    </div>
  </div>
  <div className="bg-dark-900 border-t-2 border-primary">
    <div className="container mx-auto max-w-7xl py-2 px-5 flex flex-wrap flex-col sm:flex-row">
      <p className="text-gray-500 text-sm text-center sm:text-left">© 2022 Digitscom </p>
    
    </div>
  </div>
</footer>
)

}

export default Footer;